@import "../../styles/partials/global";


.projects {
    margin: 0 1rem 3rem 1rem;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 2rem;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);


    &__link {
        color: white;

        &:hover {
            color: whitesmoke;
            font-weight: bold;
        }
    }

    &:hover {
        background-color: rgba(255, 250, 233, 0.583);
    }

    &__button-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }

    &__tech {
        display: flex;
        flex-direction: column;
        color: rgb(6, 147, 234);

        @include tablet {
            flex-direction: row;
        }
    }

    &__header {
        margin: 2rem 0;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include tablet {
            flex-direction: row;

        }

        &--right {
            display: flex;

            flex-direction: column;
            padding: 1rem;

            @include tablet {
                width: 45vw;
            }

            @include desktop {
                width: 35vw;
            }

        }
    }

    &__title {
        font-size: 2rem;
    }

    &__image {
        width: 50%;

        @include tablet {
            margin: 2rem;
            width: 20rem;
            height: 30rem;
        }
    }
}