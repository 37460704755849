@import "../../styles/partials/global";

.about-section {
    margin: 0 0.5rem;
    padding: 3vw 5vw;

    @include desktop {
        padding: 3vw 15vw;
    }

    @include desktop2k {
        padding: 3vw 20vw;
    }


    @include desktop {}

    &__icon-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    &__icon {
        width: 4rem;
        height: 4rem;

        @include tablet {
            width: 4.5rem;
            height: 4.5rem;
        }

        @include desktop {
            width: 6vw;
            height: 6vh;
        }
    }

    &__icon-text {
        font-size: 0.8rem;

        @include tablet {
            font-size: 1rem;
        }

        @include desktop {
            font-size: 1.5rem;
        }
    }


    &__wrap {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
    }

    &__text {


        &::first-letter {
            -webkit-initial-letter: 4;
            initial-letter: 4;
            font-weight: 300;
        }
    }

    ;

    &__title {
        text-align: center;
    }

    &__subtitle {
        text-align: center;
        padding: 0.5rem 0;
    }
}