@import '../../styles/partials/global';

.skills {
    &__background {
        position: relative;
        overflow: hidden;

        &--area {
            background: #00ade2;
            background: -webkit-linear-gradient(to left, #8dcef1, #008db8);
            width: 100%;
            height: 55rem;
            z-index: 1;

            @include tablet {
                height: 60rem;
            }

            @include desktop {
                height: 80rem;
            }

        }

        &--circles {
            position: absolute;
            top: -1rem;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: 2;

        }

        &--circles li {
            position: absolute;
            display: block;
            list-style: none;
            width: 15rem;
            height: 15rem;
            background: rgba(1, 150, 204, 0.828);
            animation: animate 40s linear infinite;
            bottom: -9.725rem;

            z-index: 3;
        }

        &--circles li:nth-child(1) {
            left: 25%;
            width: 10rem;
            height: 10rem;
            animation-delay: 0s;
            animation-duration: 15s;
            z-index: 4;
        }


        &--circles li:nth-child(2) {
            left: 10%;
            width: 12.5rem;
            height: 12.5rem;
            animation-delay: 2s;
            animation-duration: 13s;
            z-index: 5;
        }

        &--circles li:nth-child(3) {
            left: 70%;
            width: 9rem;
            height: 9rem;
            animation-delay: 4s;

            z-index: 6;
        }

        &--circles li:nth-child(4) {
            left: 40%;
            width: 7rem;
            height: 7rem;
            animation-delay: 0s;
            animation-duration: 14s;
            z-index: 7;
        }

        &--circles li:nth-child(5) {
            left: 65%;
            width: 3rem;
            height: 3rem;
            animation-delay: 0s;
            animation-duration: 8s;
            z-index: 8;
        }

        &--circles li:nth-child(6) {
            left: 75%;
            width: 7rem;
            height: 7rem;
            animation-delay: 2s;
            z-index: 9;
        }

        &--circles li:nth-child(7) {
            left: 35%;
            width: 9rem;
            height: 9rem;
            animation-delay: 7s;
            z-index: 10;
        }

        &--circles li:nth-child(8) {
            left: 50%;
            width: 3rem;
            height: 3rem;
            animation-delay: 15s;
            animation-duration: 45s;
            z-index: 12;
        }

        &--circles li:nth-child(9) {
            left: 20%;
            width: 1rem;
            height: 1rem;
            animation-delay: 2s;
            animation-duration: 35s;
            z-index: 13;
        }

        &--circles li:nth-child(10) {
            left: 85%;
            width: 50px;
            height: 50px;
            animation-delay: 0s;
            animation-duration: 11s;
            z-index: 14;
        }

        &--circles li:nth-child(10) {
            left: 85%;
            width: 3rem;
            height: 3rem;
            right: 20%;
            animation-delay: 5s;
            animation-duration: 8s;
            z-index: 14;
        }


        @keyframes animate {

            0% {
                transform: translateY(0) scale(0.3);
                opacity: 1;
                border-radius: 50%;
            }

            100% {
                transform: translateY(-62.5rem) scale(3);
                opacity: 0;
                border-radius: 100%;
            }

        }
    }

    &__content {
        z-index: 100;
        padding: 0 5vw;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

    }


    &__title {
        color: white;
        text-align: center;
        z-index: 50;
    }

    &__icons-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 1.2rem 0;
        align-items: center;

        @include tablet {
            justify-content: space-around;

        }
    }

    &__icons-text-wrap {
        display: flex;
        flex-direction: column;
        color: white;
        z-index: 50;
        align-items: center;

        @include desktop {
            padding: 0 1rem;
        }
    }


    &__icon {
        padding: 1rem;
        height: 6rem;
        width: 6rem;
        z-index: 50;


        @include desktop {
            height: 8rem;
            width: 8rem;
            padding: 2vh 4vw;
        }


    }
}