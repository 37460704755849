@import "../../styles/partials/global";

.navbar {
    display: none;

    @include tablet {
        display: flex;
    }

    &__item {
        margin: 1rem 1.3rem;


    }

    &__link {
        transition: all ease-in-out 0.3s;
        font-size: 1.1rem;
        color: rgb(0, 150, 251);



        &:hover {
            color: rgba(18, 128, 238, 0.924);
            font-weight: 800;
        }
    }

    &__list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        list-style: none;
    }


}