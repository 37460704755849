@import "./variables";
@import "./mixins";
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=Nunito+Sans:wght@300&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500&family=Square+Peg&display=swap');

body {
    font-family: 'Fira Sans', sans-serif;
    color: rgb(0, 49, 128);
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.form__input--invalid {
    border: rgb(242, 0, 0) 1px solid;
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.styles_type__2Teeh {
    transition: all 0.1s ease-in-out;

    @include tablet {
        font-size: 1.5rem;

    }
}

a {
    font-size: 1rem;
    text-decoration: none;


    &:visited {
        color: inherit;
    }


}

.mute {
    transition: all ease-in-out 0.2s;
    filter: grayscale(100%);

    &:hover {
        filter: grayscale(0%);
    }
}

.slide-up {
    animation: slideUp 0.5s;
    animation-delay: 0s;
    transition-timing-function: ease-in;


    @keyframes slideUp {
        0% {
            opacity: 0;
            transform: translateY(100%);
        }

        100% {
            opacity: 1;
            transform: translateY(0%);
        }
    }
}

.grow {
    transition: all .2s ease-in-out;
}

.grow:hover {
    transform: scale(1.2);

}

.shrink {
    transition: all .1s ease-in-out;
}

.shrink:hover {
    transform: scale(0.9);
}

.rotate360 {
    transition: all 2s ease-in-out;
}

.rotate360:hover {
    transform: rotate(1080deg)
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    transition: all 0.3s;
}

.switch::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 18px;
    background-color: white;
    top: 1px;
    left: 1px;
    transition: all 0.3s;
}

input[type='checkbox']:checked+.switch::after {
    transform: translateX(20px);
}

input[type='checkbox']:checked+.switch {
    background-color: #7983ff;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.button {
    background-color: royalblue;
    box-shadow: none;
    font-size: 1.5rem;
    color: white;
    border-radius: 1rem;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    box-shadow: 0px 8px 15px rgba(118, 189, 247, 0.448);
    text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: rgb(0, 49, 128);
    font-family: 'Fira Sans', sans-serif;
    font-weight: 300;
    margin: 0;
    line-height: 2;
}

h1 {
    font-size: 2rem;
    padding: 0.5rem 0;
    font-weight: 500;


    @include tablet {
        font-size: 3rem;
        font-weight: 800
    }


}

h2 {
    font-size: 1.5rem;
    font-weight: 400;

    @include tablet {
        font-size: 2rem;
    }

    @include desktop {
        font-size: 2.5rem;

    }
}

h3 {
    font-size: 1rem;
    font-weight: 500;
    margin: 0.5rem;

    @include tablet {
        font-size: 1.2rem;
    }

    @include desktop {
        line-height: 1.3;
    }
}

h4 {
    font-size: 1.1rem;
    font-weight: 400;

}

p {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 100;
    font-size: 1.125rem;
    line-height: 1.4;

    @include tablet {
        font-size: 1.3rem;

    }

    @include desktop {
        font-size: 1.5rem;
        line-height: 1.5;
    }
}

@keyframes donut-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.half {
    width: 40%;
}

.spinner {
    display: inline-block;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #7983ff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: donut-spin 1.2s linear infinite;
}