@import "../../styles/partials/global";

.navbar-mobile {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    align-content: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    display: flex;

    &__title {
        font-size: 3rem;
        @include logo-font;
    }

    &__close {
        position: absolute;
        right: 0;
        width: 1rem;
        height: 1rem;
        margin: 1rem;
        padding: 0.5rem;
        cursor: pointer;
        background-image: url("../../../public/Icons/close.svg");
        background-repeat: no-repeat;
        background-size: cover;

    }

    &__list {
        margin: 0;
        padding: 0;
    }

    &__wrap {

        background: rgba(255, 255, 255, 0.731);
        border-radius: 1rem;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        position: absolute;
        top: 1rem;
        left: 1rem;
        width: 90vw;
        height: 75vh;
        text-align: center;
        margin-top: 1rem;

    }

    &__item {
        color: rgb(23, 23, 119);
        font-size: 1.5rem;
        width: 100%;
        padding: 1rem 0;
        list-style: none;
        cursor: pointer;
    }

    &__link {
        text-decoration: none;
    }
}