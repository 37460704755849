@use '../../styles/partials/global'as *;

.project-card {

    display: flex;
    align-items: center;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 0 0 0 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: calc(100% - 2rem);
    transition: all ease-in-out 0.3s;
    margin: 0.5rem 0;

    &:hover {

        background-color: rgba(255, 250, 233, 0.583);


    }



    &__desc {
        display: none;


        @include desktop {
            display: flex;
            font-size: 1rem;
            margin: 0.5rem 1rem;
            justify-content: space-between;


        }

    }

    &__text-wrap {
        display: flex;
        text-align: none;
        flex-direction: column;
        width: 100%;
    }



    &__name {
        width: 100%;
        padding: 0.5rem 6vw;

        @include tablet {
            padding: 0.5rem 3vw;
        }

        @include desktop {
            padding: 0.5rem 0;
        }

    }

    &__img {
        width: 5rem;
        height: 5rem;
        margin: 1rem;

    }
}