@import '../../styles/partials/global';

.header {
    position: fixed;
    padding: 0.1rem 0;
    z-index: 100;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 1rem 1rem;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(7.5px);
    border: 1px solid rgba(15, 121, 243, 0.19);
    transition: all 0.5s ease-in-out;
    background: rgba(255, 255, 255, 0.175);

    @include tablet {
        justify-content: space-evenly;
    }

    &--mobile {

        background: url('../../../public/Icons/menu.svg');
        height: 2rem;
        width: 2rem;
        margin-right: 1rem;


        @include tablet {
            display: none;
            margin-right: 0;
        }
    }

    &__title {
        color: rgba(2, 38, 88, 0.993);
        padding-left: 30vw;
        font-size: 3rem;

        @include tablet {

            padding-left: 0;
        }

        &:hover {
            color: rgb(37, 146, 242);

        }

        @include logo-font;
    }


}

.header-bg {

    background: rgba(249, 212, 119, 0.599);
}