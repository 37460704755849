@import "../../styles/partials/global";

.hero {
    // top: 5rem;
    width: 100%;
    // height: 60vh;
    min-height: 25rem;
    background: linear-gradient(360deg, rgb(66, 155, 234) 4%, rgb(1, 16, 38) 92%);
    position: relative;
    z-index: 20;
    box-shadow: inset 0 0 2000px rgba(86, 96, 214, 0.5);
    overflow: hidden;




    &__title {
        text-align: center;
        position: absolute;
        color: white;
        left: 15%;
        z-index: 200;
        bottom: 35%;

        @include tablet {
            left: 30%;

        }


    }

    &__emoji {
        position: relative;
        left: 30%;
        bottom: -4rem;
        font-size: 7rem;
        z-index: 200;

        @include tablet {
            bottom: -1.2rem;
        }

        @include desktop {
            font-size: 8rem;
        }


    }

    &__page-title {
        padding: 2rem 0;
        text-align: center;
        font-size: 2rem;
        color: white;

    }

}

.cloud {
    display: inline-block;
    background: rgb(173, 173, 173);
    width: 150px;
    height: 120px;
    border-radius: 50%;
    position: relative;
    top: 15rem;
    -webkit-filter: blur(0.5rem);
    z-index: 11;
    left: -60px;
    animation: 80000ms cloudAnimation linear infinite;
    opacity: 0.76;

    @include tablet {
        left: -2rem;
        width: 200px;
        height: 200px;
    }
}

.cloud:before {
    content: "";
    display: inline-block;
    background: rgba(193, 186, 186, 0.479);
    width: 150px;
    height: 100px;
    -webkit-filter: blur(3px);
    position: relative;
    border-radius: 50%;
    top: -30px;
    left: 60px;

    @include tablet {
        width: 200px;
        height: 170px;
    }

}

.cloud:after {
    content: "";
    display: inline-block;
    background: rgba(174, 170, 170, 0.722);
    width: 200px;
    height: 100px;
    -webkit-filter: blur(3px);
    position: relative;
    border-radius: 50%;
    top: -80px;
    left: 70px;

    @include tablet {
        top: -180px;
        left: 100px;
        width: 15.75rem;
        height: 13.5rem;
    }
}

.cloud:first-child {
    zoom: 1.5;
}

@keyframes cloudAnimation {
    0% {
        transform: translateX(-40vw);
    }

    100% {
        transform: translateX(90vw);
    }
}

.sun {
    position: relative;
    top: -5rem;
    left: 50%;
    display: inline-block;
    border-radius: 50%;
    background: orange;
    box-shadow: 0 0 100px orange,
        0 0 80px orange,
        0 0 200px yellow,
        inset 0 0 80px yellow;
    z-index: 12;
    z-index: -99;
    height: 6.125rem;
    width: 6.125rem;

    @include tablet {
        height: 12.5rem;
        width: 12.5rem;
    }
}