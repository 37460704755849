@import '../../styles/partials/global';

.action {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
    background: rgba(175, 212, 255, 0.33);
    border-radius: 2rem 2rem 0 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(7.5px);
    border: 1px solid rgba(15, 121, 243, 0.19);

    &__wrap {
        display: flex;
        justify-content: center;

    }

    &__button {
        border-radius: 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.4rem;
        display: flex;
        flex-direction: column;
        font-size: 0.5rem;
        cursor: pointer;

        &:hover {}
    }

    &__icon {
        width: 2.5rem;
        height: 2.5rem;
    }

    &__title {
        font-size: 1rem;
        font-weight: 400;
        text-align: center;

        @include tablet {
            font-size: 1.4rem;
        }

        @include desktop {
            font-size: 2rem;
        }
    }
}