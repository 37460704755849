@mixin tablet {
    @media (min-width: 768px) {
        @content
    }
}

;

@mixin desktop {
    @media (min-width: 1280px) {
        @content
    }
}

;

@mixin desktop2k {
    @media (min-width: 1920px) {
        @content
    }
}

@mixin logo-font {
    font-family: 'Square Peg', cursive;
}

@mixin glass {
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}