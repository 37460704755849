@import '../../styles/partials/global';

.contact-us {
    padding: 3vw 5vw;

    @include desktop {
        padding: 3vw 15vw;
    }

    @include desktop2k {
        padding: 3vw 20vw;
    }

    &__title {
        text-align: center;
    }

    &__divide {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include tablet {
            flex-direction: row;
        }


    }

    &__field {
        width: 100%;
        border-radius: 0.5rem;
        box-shadow: 0;
        border: 1px solid grey;

        &::placeholder {
            padding: 0.5rem;

        }

        @include tablet {
            width: calc(50% - 1rem);
        }
    }

    &__message {
        width: 100%;
        border: 1px solid grey;
        border-radius: 0.5rem;
        height: 5rem;
        resize: none;

        &::placeholder {
            padding: 0.5rem;

        }


    }

    &__input {
        height: 2rem;
        margin: 1rem 0;



    }

    &__button {
        margin: 1rem 0;
        width: 100%;
    }

    &__form {
        margin: 2rem 0;
    }
}