@import "../../styles/partials/global";


.project-section {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    animation: gradient 1s ease infinite;
    background-size: 400% 400%;
    background: linear-gradient(to top, #fcd600, #ffaa14, #ffaf21, #ffb32b, #ffb734, #ffb533, #ffb433, #ffb232, #ffaa28, #ffa21d, #ff9910, #ff9000);


    @include desktop {
        padding: 5rem 0;
    }

    &__title {
        text-align: center;
    }

    &__subtitle {
        margin: 0;
        text-align: center;
        border-radius: 1rem 1rem 0 0;
        padding: 0.5rem 0;
        width: calc(100% - 2rem);
        @include glass;

    }

    &__end:empty {
        width: calc(100% - 2rem);
        padding: 1rem 0;
        border-radius: 0 0 1rem 1rem;
        @include glass;
    }

    &__wrap {
        @include desktop {
            display: flex;
            justify-content: center;
            align-items: flex-start;
        }

    }

    &__card-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__side {
        width: 100%;
        overflow-y: scroll;
    }







}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}